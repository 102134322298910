import React, { FormEvent, useEffect, useState } from "react";
import {
  FirmwareBundle,
  updateFirmwareBundleById,
} from "../../../../../BytebeamClient";
import {
  Button,
  Dropdown,
  Form,
  Icon,
  Input,
  Modal,
  Popup,
} from "semantic-ui-react";
import { getHumanReadableFileSizeString } from "../../../util";
import { beamtoast } from "../../../../common/CustomToast";

type EditFirmwareBundleModalProps = {
  readonly isOpen: boolean;
  readonly close: () => void;
  readonly firmwareBundle: FirmwareBundle;
  readonly fillFirmwareBundlesTable: () => void;
};

function EditFirmwareBundleModal(props: EditFirmwareBundleModalProps) {
  const { isOpen, close, firmwareBundle, fillFirmwareBundlesTable } = props;

  const [uncompressedSize, setUncompressedSize] = useState<number>(
    firmwareBundle.uncompressed_size
  );
  const [previousUncompressedSize, setPreviousUncompressedSize] =
    useState<number>(firmwareBundle.uncompressed_size);
  const [unit, setUnit] = useState<string>("KiB");
  const [previouslySelectedUnit, setPreviouslySelectedUnit] =
    useState<string>("KiB");
  const [disableUploadButton, setDisableUploadButton] = useState<boolean>(true);

  const handleSubmit = async (event: FormEvent<HTMLButtonElement>) => {
    event.preventDefault();

    let uploadSize: number = 0;
    if (
      uncompressedSize === previousUncompressedSize &&
      unit === previouslySelectedUnit
    )
      beamtoast.error("No change in values detected!");
    else if (uncompressedSize <= 0) {
      beamtoast.error("Uncompressed size must be greater than 0!");
      return;
    } else {
      let factor = 1;

      if (unit === "KiB") {
        factor = 1024;
      } else if (unit === "MiB") {
        factor = 1024 ** 2;
      } else if (unit === "GiB") {
        factor = 1024 ** 3;
      }

      // Calculate upload size
      uploadSize = Math.ceil(uncompressedSize * factor);

      let requestBody = {
        uncompressed_size: uploadSize,
        upload_status: firmwareBundle.upload_status,
        content_length: firmwareBundle.content_length,
      };
      try {
        const res = await updateFirmwareBundleById(
          firmwareBundle.id,
          requestBody
        );

        if (res) {
          fillFirmwareBundlesTable();
          beamtoast.success("Firmware bundle updated successfully");
          onCloseModal();
        }
      } catch (error) {
        beamtoast.error("Failed to update firmware bundle");
      }
    }
  };

  // Reset state and close modal
  function onCloseModal() {
    // Reset state
    setDisableUploadButton(true);
    setUnit("KiB");
    setPreviouslySelectedUnit("KiB");
    setUncompressedSize(firmwareBundle.uncompressed_size);
    setPreviousUncompressedSize(firmwareBundle.uncompressed_size);

    // Close Modal
    close();
  }

  useEffect(() => {
    if (
      uncompressedSize === previousUncompressedSize &&
      unit === previouslySelectedUnit
    )
      setDisableUploadButton(true);
    else setDisableUploadButton(false);
  }, [
    uncompressedSize,
    unit,
    previousUncompressedSize,
    previouslySelectedUnit,
  ]);

  useEffect(() => {
    const size = firmwareBundle?.uncompressed_size;

    if (isOpen) {
      const sizeData: string[] =
        getHumanReadableFileSizeString(size).split(" ");

      setUncompressedSize(Number(sizeData[0]));
      setPreviousUncompressedSize(Number(sizeData[0]));

      setUnit(sizeData[1]);
      setPreviouslySelectedUnit(sizeData[1]);
    }
  }, [isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal
      open={isOpen}
      onClose={onCloseModal}
      size="tiny"
      className="dark"
      closeOnDimmerClick={false}
      closeOnEscape={false}
    >
      <Modal.Header>Edit Firmware Bundle</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field>
            <label
              htmlFor="firmware_uncompressed_size"
              style={{ display: "flex", gap: "8px" }}
            >
              Uncompressed File Size
              <Popup
                inverted
                trigger={
                  <Icon
                    name="question circle"
                    style={{ marginBottom: "2px", marginLeft: "2px" }}
                  />
                }
                content={
                  "Estimated disk space needed to successfully uncompress file on your device"
                }
                position="top center"
              />
            </label>
            <div
              style={{
                display: "flex",
                flexWrap: "nowrap",
                gap: "12px",
                width: "100%",
              }}
            >
              <Input
                min="0"
                type="number"
                id="firmware_bundle_uncompressed_size"
                placeholder="value"
                value={uncompressedSize}
                onChange={(e) => {
                  const value =
                    unit === "B"
                      ? parseInt(e.target.value)
                      : parseFloat(e.target.value);
                  setUncompressedSize(value);
                }}
                step={unit === "B" ? "1" : "0.01"}
                style={{ minWidth: "calc(100% - 124px)" }}
              />
              <Dropdown
                id="firmware_uncompressed_size_unit"
                selection
                options={[
                  { key: "B", value: "B", text: "B" },
                  { key: "KiB", value: "KiB", text: "KiB" },
                  { key: "MiB", value: "MiB", text: "MiB" },
                  { key: "GiB", value: "GiB", text: "GiB" },
                ]}
                value={unit}
                onChange={(e, { value }) => {
                  setUnit(value as string);
                }}
                style={{ minWidth: "100px" }}
              />
            </div>
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          id="cancel_button"
          secondary
          onClick={() => {
            onCloseModal();
          }}
        >
          <Icon name="remove" /> Discard
        </Button>
        <Button
          id="submit_button"
          disabled={disableUploadButton}
          primary
          onClick={async (e) => {
            await handleSubmit(e);
          }}
        >
          <Icon name="checkmark" /> Upload
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default EditFirmwareBundleModal;
